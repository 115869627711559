import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { Capacitor } from "@capacitor/core";

const externalLinkClickListener = (el: HTMLElement | undefined) => {
  if (el) {
    const anchors = el.querySelectorAll("a");
    const browse = InAppBrowser;

    anchors.forEach(function (anchor: HTMLAnchorElement) {
      anchor.addEventListener(
        "click",
        (event: Event) => {
          event.preventDefault();
          let target = "_self";
          const hrefTarget = anchor.target;

          if (hrefTarget) {
            target = hrefTarget;
          }

          if (Capacitor.isNativePlatform()) {
            if (hrefTarget.includes("_blank")) {
              target = "_system";
            } else {
              target = "_blank";
            }
          }

          browse.create(encodeURI(anchor.href), target, {
            toolbarposition: "top",
            hidenavigationbuttons: "yes",
            clearcache: "yes",
            clearsessioncache: "yes",
            hideurlbar: "yes",
            zoom: "no",
          });
        },
        false
      );
    });
  }
};

export default class IFrameExternalURL {
  public beforeMount(el: HTMLIFrameElement, binding: any, vNode: any) {
    el.addEventListener("load", () => externalLinkClickListener(el.contentWindow?.document.body));
  }

  public unmounted(el: HTMLIFrameElement, binding: any, vnode: any, prevVnode: any) {
    el.removeEventListener("load", () => externalLinkClickListener(el.contentWindow?.document.body));
  }
}
