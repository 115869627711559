import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = ["srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_resize = _resolveDirective("resize")!
  const _directive_external_url = _resolveDirective("external-url")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("iframe", {
      srcdoc: _ctx.body,
      class: "border-0",
      width: "100%"
    }, null, 8, _hoisted_2), [
      [_directive_resize, { 'iframe-click': _ctx.iframeClick }],
      [_directive_external_url]
    ])
  ]))
}